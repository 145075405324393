// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Stack, Text } from '@mantine/core';
import { FC, useContext } from 'react';
import { EventContext } from '../../Service/Context/EventContext';
import { EventDate } from '../../Types/Event';

const EventBreadCrumbs: FC = () => {
  const { dates } = useContext<any>(EventContext);
  const regularDates = dates.filter((d: EventDate) => d.type === 'REGULAR_DATE');
  const withoutDates = regularDates.length === 0;
  const primaryDate = dates.find((ed: EventDate) => ed.useAsPrimary);

  return (
    <Stack spacing={3}>
      {withoutDates && <Text size="xl">Bisher kein Termin</Text>}
      {!withoutDates && (
        <>
          <Text>Event am</Text>
          <Text size="xl" weight="bolder">{`${dates[0].weekday}, ${new Intl.DateTimeFormat('de').format(
            new Date(primaryDate ? primaryDate.date : dates[0].date),
          )}`}</Text>
        </>
      )}
    </Stack>
  );
};

export default EventBreadCrumbs;
