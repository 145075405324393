// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Box, Group, Select, Text, TextInput, Tooltip } from '@mantine/core';
import { FC, useContext } from 'react';
import { IconChevronDown, IconLocation, IconX } from '@tabler/icons';
import { EventDate } from '../../Types/Event';
import BookitupDateInput from '../../Atoms/inputs/BookitupDateInput';
import BookitupTimeInput from '../../Atoms/inputs/BookitupTimeInput';
import { EventLocationContextProps } from '../../Types/LocationT';
import { EventLocationContext } from '../../Service/Context/EventLocationContext';
import { DataS } from '../../Service/DataS';
import EmptyText from '../../Atoms/EmptyText';
import BookitupBadge from '../../Atoms/BookitupBadge';

interface Props {
  eventDate: EventDate;
  onUpdate: (patch: Partial<EventDate>) => Promise<boolean>;
}

const EventDateItem: FC<Props> = ({ eventDate, onUpdate }) => {
  const { locations } = useContext<any>(EventLocationContext) as EventLocationContextProps;
  const { type, date, timeFrom, timeTo, hours, description, locationId, subject, useAsPrimary } = eventDate;

  return (
    <Box p="xs" sx={{ borderRadius: 10, backgroundColor: '#F1F1F1', overflow: 'hidden' }}>
      {useAsPrimary && (
        <Tooltip
          multiline
          offset={-5}
          transition="slide-down"
          width={250}
          label="Gibt die Haupt-Eventzeit an. Diese wird von den Platzhaltern verwendet."
          position="bottom-start"
        >
          <div>
            <BookitupBadge mb="sm" title="primär" />
          </div>
        </Tooltip>
      )}
      {type === 'APPOINTMENT' && (
        <TextInput
          size="sm"
          mb="xs"
          defaultValue={subject ?? ''}
          onBlur={(e) => onUpdate({ subject: e.currentTarget.value })}
        />
      )}
      <BookitupDateInput patchCallback={(val) => onUpdate({ date: val })} value={new Date(date)} />
      <Group my="xs" spacing={5}>
        <BookitupTimeInput patchCallback={(val) => onUpdate({ timeFrom: val })} initialValue={timeFrom} />
        <BookitupTimeInput patchCallback={(val) => onUpdate({ timeTo: val })} initialValue={timeTo} />
        {hours && <Text color="blue" ml="xs" weight="bolder">{`${hours}h`}</Text>}
      </Group>
      <Select
        size="sm"
        icon={<IconLocation size={20} />}
        placeholder="Location"
        value={locationId ? locationId.toString() : null}
        data={DataS.getLocationOptions(locations)}
        onChange={(val) => val && onUpdate({ locationId: Number(val) })}
        rightSection={
          locationId ? (
            <IconX size={16} style={{ cursor: 'pointer' }} onClick={() => onUpdate({ locationId: null })} />
          ) : (
            <IconChevronDown size={14} />
          )
        }
        nothingFound={<EmptyText />}
      />
      {type === 'REGULAR_DATE' && (
        <TextInput
          size="sm"
          mt="xs"
          placeholder="Beschreibung"
          defaultValue={description ?? ''}
          onBlur={(e) => onUpdate({ description: e.currentTarget.value })}
        />
      )}
    </Box>
  );
};

export default EventDateItem;
